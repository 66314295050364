import React, {useState} from 'react';
import {StaticImage} from "gatsby-plugin-image";
import {Trans} from "gatsby-plugin-react-i18next";
import Accordion from '../../../ui/legacy/Accordion/Accordion'


let dataSheetsPivotKiosk = [
  {
    "title": "Smart Keypad with Mobile ID | Deadbolt Horizontal Body",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/3g8DPRw6hJZOJcbjwwgl4T/64c9165e4af9bcf2e02397e65581a510/DS-D6AKN-H-Bolt-Cable-DEN.pdf",
      "fileName": "DS-D6AKN-H-Bolt-Cable-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let installGuidesPivotKiosk = [
  {
    "title": "Install Guide Keypad & RFID",
    "file": {
      "url": "https://downloads.ctfassets.net/q5vaqi9g69zw/2PkM8fC8UcM6Me6uA7DIK1/6ccaca155307b00ead1745d4af720e8c/IG-D6AN-KR-DEN.pdf",
      "fileName": "IG-D6AN-KR-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let productGuidesPivotKiosk = [
  {
    "title": "Keypad Shared & Assigned Use Functionality",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/4Ym8u81r95Xh049BIY1xH6/33ec7b5073b95453ac737e4e3bf6271a/PG-D6AKN-D-DEN.pdf",
      "fileName": "PG-D6AKN-D-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let dataSheetsPivot = [
  {
    "title": "Smart RFID with Mobile ID | Deadbolt Narrow Body",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/7byhJKWiHkzuazpZFECOy6/a20f29f9d2a240c4255c2b4f04891268/DS-D6ARN-N-Bolt-Cable-DEN.pdf",
      "fileName": "DS-D6ARN-N-Bolt-Cable-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let installGuidesPivot = [
  {
    "title": "Aspire Install Guide",
    "file": {
      "url": "https://downloads.ctfassets.net/q5vaqi9g69zw/6iDWITTIFq35t8MG2AXy17/66d9930f9da8ec23a9a621371ee2f5a8/IG-D6AN-KR-DEN.pdf",
      "fileName": "IG-D6AN-KR-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let productGuidesPivot = [
  {
    "title": "Touch-Free RFID Shared Use Functionality",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/T1hWORlXmQfCmOTnn5Ogp/49a6e0a4dbae7dd4284c166ce779a745/PG-D6ARN-D-DEN.pdf",
      "fileName": "PG-D6ARN-D-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];

const Tab = (props) => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const getActiveClass = (index, className) => {
    return toggleState === index ? className : '';
  };

  return (
      <div className={'tab-container'}>
        <ul className="tab-list unstyled-list">
          <li
              className={getActiveClass(1, 'active')}
              onClick={() => toggleTab(1)}
          >
            Pivot
          </li>
          <li
              className={getActiveClass(2, 'active')}
              onClick={() => toggleTab(2)}
          >
            Pivot Pro
          </li>
        </ul>
        <div className="tab-content-container">
          <div className={"tab-content " + getActiveClass(1, 'active')}>
            <div className="front-rear">
              <StaticImage
                  src={'./images/pivot-system.png'}
                  loading={'lazy'}
                  width={228}
                  height={309}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Pivot Plus"
              />
            </div>
            <div className="lists">
              {/*DOCUMENTS*/}
              {/*<div label="Document Library">*/}
              {/*  <Accordion clsName={'next-support'}>*/}
              {/*    <div label="Data Sheets FPO">*/}
              {/*      <div className="step-images">*/}
              {/*        <ul>*/}
              {/*          {*/}
              {/*            dataSheetsPivotKiosk.map((doc, index) => (*/}
              {/*                    <li key={index}>*/}
              {/*                      <li key={index}>*/}
              {/*                        /!*<a href={doc.file.url} target={'_blank'}>{doc.title}</a>*!/*/}
              {/*                        FPO*/}
              {/*                      </li>*/}
              {/*                    </li>*/}
              {/*                )*/}
              {/*            )*/}
              {/*          }*/}
              {/*        </ul>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div label="Product Guides FPO">*/}
              {/*      <div className="step-images">*/}
              {/*        <ul>*/}
              {/*          {*/}
              {/*            productGuidesPivotKiosk.map((doc, index) => (*/}
              {/*                    <li key={index}>*/}
              {/*                      /!*<a href={doc.file.url} target={'_blank'}>{doc.title}</a>*!/*/}
              {/*                      FPO*/}
              {/*                    </li>*/}
              {/*                )*/}
              {/*            )*/}
              {/*          }*/}
              {/*        </ul>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </Accordion>*/}
              {/*</div>*/}
              <p>For documentation or support, please email <a href="mailto:sales@digilock.com">sales@digilock.com</a>.</p>

            </div>
          </div>
          <div className={"tab-content " + getActiveClass(2, 'active')}>
            <div className="front-rear">
              <StaticImage
                  src={'./images/pivot.png'}
                  loading={'lazy'}
                  width={228}
                  height={308}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Pivot"
              />
            </div>
            <div className="lists">
                {/*DOCUMENTS*/}
                {/*<div label="Document Library">*/}
                {/*  <Accordion clsName={'next-support'}>*/}
                {/*    <div label="Data Sheets FPO">*/}
                {/*      <div className="step-images">*/}
                {/*        <ul>*/}
                {/*          {*/}
                {/*            dataSheetsPivot.map((doc, index) => (*/}
                {/*                    <li key={index}>*/}
                {/*                      <li key={index}>*/}
                {/*                        /!*<a href={doc.file.url} target={'_blank'}>{doc.title}</a>*!/*/}
                {/*                        FPO*/}
                {/*                      </li>*/}
                {/*                    </li>*/}
                {/*                )*/}
                {/*            )*/}
                {/*          }*/}
                {/*        </ul>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*    <div label="Product Guides FPO">*/}
                {/*      <div className="step-images">*/}
                {/*        <ul>*/}
                {/*          {*/}
                {/*            productGuidesPivot.map((doc, index) => (*/}
                {/*                    <li key={index}>*/}
                {/*                      /!*<a href={doc.file.url} target={'_blank'}>{doc.title}</a>*!/*/}
                {/*                      FPO*/}
                {/*                    </li>*/}
                {/*                )*/}
                {/*            )*/}
                {/*          }*/}
                {/*        </ul>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </Accordion>*/}
                {/*</div>*/}
              <p>For documentation or support, please email <a href="mailto:sales@digilock.com">sales@digilock.com</a>.</p>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Tab;